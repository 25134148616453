<template>
  <div>
    <a-row :gutter="16">
      <a-col :sm="24" :lg="5">
        <div class="card">
          <div class="card-header card-header-flex">
            <div
              class="d-flex justify-content-between align-items-center w-100"
            >
              <div>
                <h2 class="font-size-18">Menu</h2>
              </div>
            </div>
          </div>
          <div class="card-body">
            <Menu
              :menus="menus"
              :menuActive="menuActive"
              @menu-change="menuChange"
            />
          </div>
        </div>
      </a-col>
      <a-col :sm="24" :lg="19">
        <div class="card">
          <div class="card-header card-header-flex">
            <div
              class="d-flex justify-content-between align-items-center w-100"
            >
              <div>
                <h2 class="font-size-18">{{ menuName }}</h2>
              </div>
            </div>
          </div>
          <div class="card-body mt-3">
            <template v-if="menuActive === 1">
              <RPP
                :subjects="subjects"
                :levels="levels"
                :reportConfig="{ tahunAjaran, kepalaSekolah }"
                v-if="subjects.length && levels.length"
                @changeMapel="changeMapel"
              />
            </template>
            <template v-else-if="menuActive === 2">
              <Kd
                :subjects="subjects"
                :levels="levels"
                v-if="subjects.length && levels.length"
                @changeMapel="changeMapel"
              />
            </template>
            <template v-else-if="menuActive === 3">
              <Topic
                :subjects="subjects"
                :levels="levels"
                v-if="subjects.length && levels.length"
                @changeMapel="changeMapel"
              />
            </template>
            <template v-else-if="menuActive === 4">
              <Session
                :subjects="subjects"
                :levels="levels"
                v-if="subjects.length && levels.length"
                @changeMapel="changeMapel"
              />
            </template>
            <template v-else-if="menuActive === 5">
              <TemplateReportDescription
                :subjects="subjects"
                :levels="levels"
                v-if="subjects.length && levels.length"
                @changeMapel="changeMapel"
              />
            </template>
            <!-- <template v-else-if="menuActive === 6">
              <QuestionBank
                :subjects="subjects"
                :levels="levels"
                v-if="subjects.length && levels.length"
                @changeMapel="changeMapel"
              />
            </template> -->
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
const Menu = () => import('@/components/app/MenuV2')
const RPP = () => import('@/views/Teacher/Planning/New/RPP')
const Kd = () => import('@/views/Teacher/Planning/New/Kd')
const Topic = () => import('@/views/Teacher/Planning/New/Topic')
const Session = () => import('@/views/Teacher/Planning/New/Session')
const TemplateReportDescription = () => import('@/views/Teacher/Planning/New/TemplateReportDescription')
// const QuestionBank = () => import('@/views/Teacher/Planning/New/QuestionBank')

export default {
  name: 'Master',
  components: {
    Menu,
    RPP,
    Kd,
    Topic,
    Session,
    TemplateReportDescription,
    // QuestionBank,
  },
  data() {
    return {
      menuActive: 1,
      subjects: [],
      levels: [],
      tahunAjaran: null,
      kepalaSekolah: null,
    }
  },
  methods: {
    menuChange(payload) {
      this.menuActive = payload
    },
    async fetchSubjectsAndLevelsByTeacher() {
      try {
        const { data, tahun_ajaran: tahun, kepala_sekolah: kepsek } = await this.$store.dispatch('subject/FETCH_TEACHER_SUBJECTS')

        this.subjects = data.subjects
        if (!this.subjects.length) {
          this.$notification.error({
            message: 'HAVE NO SCHEDULE.',
            description: "You don't have any schedule on your subject this semester. Please contact admin.",
          })
        }

        this.tahunAjaran = tahun
        this.kepalaSekolah = kepsek

        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    changeMapel(idMapel) {
      const subject = this.subjects.find(subject => subject.id === Number(idMapel))
      this.levels = subject.levels
    },
  },
  computed: {
    menuName() {
      let menu = ''
      this.menus.forEach(el => {
        if (el.sub) {
          const find = el.children.filter(elChildren => elChildren.key === this.menuActive)[0]
          if (find !== undefined) {
            menu = find.menu
          }
        } else {
          if (el.key === this.menuActive) {
            menu = el.menu
          }
        }
      })
      return menu
    },
    menus() {
      let menus = [
        {
          key: 1,
          menu: 'RPP',
        },
        {
          key: 2,
          menu: 'Basic Competence',
        },
        {
          key: 3,
          menu: 'Topic & Material',
        },
        {
          key: 4,
          menu: 'Session',
        },
        // {
        //   key: 6,
        //   menu: 'Question Bank',
        // },
      ]

      if (this.institusi.template_report_description === 'by subject teacher') {
        menus = [
          ...menus,
          {
            key: 5,
            menu: 'Template Report Description',
          },
        ]
      }
      return menus
    },
    institusi() {
      return this.$store.state.master.institusi
    },
  },
  async created() {
    try {
      await this.fetchSubjectsAndLevelsByTeacher()

      // initialization
      if (this.subjects.length) {
        this.levels = this.subjects[0].levels
      }
    } catch (err) {
      console.log(err)
      this.$notification.error({
        message: 'ERROR_SERVER',
      })
    }
  },
}
</script>

<style lang="css">
</style>
